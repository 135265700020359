<template>
<div>
	<register-payment></register-payment>

	<view-component
	show_filter_modal
	ask_selectable
	model_name="user">
		<template #table_right_options="data">
			<buttons
			:commerce="data.model"></buttons>	
		</template>
	</view-component>	
</div>
</template>
<script>
export default {
	components: {
		ViewComponent: () => import('@/common-vue/components/view/Index'),
		RegisterPayment: () => import('@/components/commerce/modals/RegisterPayment'),
		Buttons: () => import('@/components/commerce/components/Buttons'),
	}
}
</script>